import {HttpClient} from '@angular/common/http';
import {firstValueFrom, map} from 'rxjs';
import {GoogleLoginProvider, MicrosoftLoginProvider} from '@abacritt/angularx-social-login';

interface AuthProviderConfig {
  googleClientId: string
  microsoftClientId: string
}

export const loadSocialAuthConfig = (http: HttpClient) => firstValueFrom(
  http.get<AuthProviderConfig>('api/auth/provider').pipe(
    map(({googleClientId}) => ({
    // map(({googleClientId, microsoftClientId}) => ({
        autoLogin: false,
        providers: [
          {
            id: 'GOOGLE',
            provider: new GoogleLoginProvider(googleClientId,
              {
                oneTapEnabled: false
              })
          },
          // {
          //   id: 'MICROSOFT',
          //   provider: new MicrosoftLoginProvider(microsoftClientId,
          //     {
          //       // scopes: ['user.read', 'openid', 'offline_access'],
          //       // scopes: ['openid', 'profile'],
          //       authority: 'https://login.microsoftonline.com/common'
          //     })
          // }
        ],
        onError: (err: any) => console.error(err)
      })
    )
  ))
